import React, { useEffect, useState } from 'react';
import CallToAction from './call-to-action';

const HubspotGetStartedForm = ({
  title,
  subtitle,
  formId,
  formSuccessMessageTitle,
  cta,
}) => {
  const [submitSucess, setSubmitSucess] = useState(false);

  const createForm = () => {
    window.hbspt.forms.create({
      region: 'na1',
      portalId: '23114811',
      formId: formId,
      target: '#hubspotGetStartedForm',
    });
  };

  const onHubSubmit = (event) => {
    if (
      event.data.type === 'hsFormCallback' &&
      event.data.eventName === 'onFormSubmitted'
    ) {
      setSubmitSucess(true);
    }
  };

  useEffect(() => {
    window.addEventListener('message', onHubSubmit);
    if (!window.hbspt) {
      const script = document.createElement('script');
      script.src = 'https://js.hsforms.net/forms/v2.js';
      document.body.appendChild(script);
      script.addEventListener('load', () => {
        if (window.hbspt) {
          createForm();
        }
      });
    } else {
      createForm();
    }
    return () => {
      window.removeEventListener('message', onHubSubmit);
    };
  }, []);

  return (
    <div className="mt-7">
      {submitSucess ? (
        <div>
          <p>{formSuccessMessageTitle}</p>
        </div>
      ) : (
        <div>
          <h1 className="text-[30px] md:text-[40px] xl:text-[56px]">{title}</h1>
          <p className="font-outfit mb-6 mt-4 text-lg font-light xl:max-w-[500px]">
            {subtitle}
          </p>
          <div id="hubspotGetStartedForm" />
        </div>
      )}
    </div>
  );
};

export default HubspotGetStartedForm;
